<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<form v-else class="main-wrapper mt-4 mb-3" @submit.prevent="handleSubmit">
		<CRow class="mb-5">
			<!-- Category level -->
			<CCol lg="12">
				<h4 class="mb-4">
					Category level
				</h4>
				<p class="label level-label">
					Creating category
				</p>
				<BaseButtonGroup
					v-model="level"
					:list="CATEGORY_LEVEL_OPTIONS"
					disabled
				/>
				<CRow v-if="isParentSelectable" class="row-parent-category">
					<CCol md="6">
						<BaseDropDown
							v-model="$v.categoryParentLevelOne.$model"
							:options="categoryParentLevelOneOptions"
							:searchable="false"
							:is-valid="isValidCategoryParentLevelOne"
							:invalid-feedback="validatecategoryParentLevelOneMessage"
							label-drop-down="Under parent category (level 1)*"
							label="name"
							track-by="value"
							placeholder="Selection"
							class="select-custom"
							test-id="category-level-one-dropdown"
							@input="handleSelectedCategoryParentLevelOne"
						/>
					</CCol>
					<CCol v-if="isLevelThree" md="6">
						<BaseDropDown
							v-model="$v.categoryParentLevelTwo.$model"
							:options="categoryParentLevelTwoOptions"
							:searchable="false"
							:is-valid="isValidCategoryParentLevelTwo"
							:invalid-feedback="validatecategoryParentLevelTwoMessage"
							:disabled="!categoryParentLevelOne"
							label-drop-down="Under parent category (level 2)*"
							label="name"
							track-by="value"
							placeholder="Selection"
							class="select-custom"
							test-id="category-level-two-dropdown"
							@input="clearError"
						/>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Category level -->

			<!-- General info -->
			<CCol lg="12">
				<div
					class="mb-4 d-flex justify-content-between align-items-center"
				>
					<h4 class="mb-0">
						General info
					</h4>
					<p
						v-if="categoryInfo.id"
						class="typo-body-2 mb-0 category-id"
					>
						{{ $t("page.categories.edit.categoryId") }}:
						{{ categoryInfo.id }}
					</p>
				</div>
				<CRow>
					<CCol md="6">
						<CInput
							v-model.trim="$v.name.$model"
							data-id="edit-category-name-en"
							:is-valid="isValidName"
							:invalid-feedback="validateNameMessage"
							label="Category name (EN)*"
							@input="clearError"
							@blur="handleAutoFillMetaTitleAndSlug"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="$v.nameTH.$model"
							data-id="edit-category-name-th"
							:is-valid="isValidNameTH"
							:invalid-feedback="validateNameTHMessage"
							label="Category name (TH)*"
							@input="clearError"
							@blur="handleAutoFillMetaTitleTH"
						/>
					</CCol>
					<CCol md="12">
						<CInput
							v-model.trim="$v.slug.$model"
							:is-valid="isValidSlug"
							:invalid-feedback="validateSlugMessage"
							:description="slugHelperText"
							label="Slug"
							@input="clearError"
						/>
					</CCol>
					<CCol md="12">
						<CInput
							v-model.trim="$v.customUrl.$model"
							:is-valid="!$v.customUrl.$error && null"
							:invalid-feedback="$t('global.error.url')"
							:description="'Allow only url. example: https://www.studio7thailand.com'"
							label="Custom URL"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="commission"
							data-id="edit-commission"
							label="Commission (%)"
							@input="clearError"
						/>
					</CCol>
					<template v-if="isParentSelectable">
						<CCol xl="12" class="margin-bottom-32">
							<p class="label level-label">
								Icon image
							</p>
							<CategoryIcon :icon.sync="icon" />
						</CCol>
						<CCol
							xl="12"
							class="d-flex align-items-center margin-bottom-32"
						>
							<label class="toggle-label" for="category-is-new">
								New category
							</label>
							<CSwitch
								id="category-is-new"
								data-id="category-is-new"
								:checked="isNew"
								variant="3d"
								size="sm"
								class="switch-custom toggle-status"
								color="success"
								@update:checked="handleUpdateIsNew"
							/>
							<span>{{ isNewText }}</span>
						</CCol>
					</template>
					<CCol
						xl="12"
						class="d-flex align-items-center margin-bottom-32"
					>
						<label class="toggle-label" for="category-status">
							Category status
						</label>
						<CSwitch
							id="category-status"
							data-id="category-status"
							:checked="status"
							variant="3d"
							size="sm"
							class="switch-custom toggle-status"
							color="success"
							@update:checked="handleUpdateStatus"
						/>
						<span>{{ statusText }}</span>
					</CCol>
					<CCol xl="12" class="d-flex align-items-center margin-bottom-32">
						<label class="toggle-label" for="category-visibility">
							Storefront visibility
						</label>
						<CSwitch
							id="category-visibility"
							:checked="visibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateVisibility"
						/>
						<span>{{ visibilityText }}</span>
					</CCol>
					<CCol xl="12" class="d-flex align-items-center">
						<label class="toggle-label" for="true-trade-in-visibility">
							TRUE / Trade in visibility
						</label>
						<CSwitch
							id="true-trade-in-visibility"
							data-id="true-trade-in-visibility"
							:checked="tradeInTruemoveVisibility"
							variant="3d"
							size="sm"
							class="switch-custom toggle-visibility"
							color="success"
							@update:checked="handleUpdateTradeInTruemoveVisibility"
						/>
						<span>{{ visibilityTradeInText }}</span>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end General info -->

			<!-- Compare -->
			<CCol lg="12">
				<h4 class="mb-4">
					Product compare
				</h4>
				<CRow>
					<CCol md="6">
						<CInput
							v-model.trim="compare"
							data-id="compare-text-en"
							label="Compare text (EN)"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CInput
							v-model.trim="compareTH"
							data-id="compare-text-th"
							label="Compare text (TH)"
							@input="clearError"
						/>
					</CCol>
					<CCol md="12">
						<CInput
							v-model.trim="$v.link.$model"
							class="margin-bottom-0-important"
							data-id="product-link"
							:is-valid="!$v.link.$error && null"
							:invalid-feedback="$t('global.error.url')"
							label="Compare Link"
							@input="clearError"
						/>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Compare -->

			<!-- Product Ranking -->
			<CCol lg="12">
				<h4 class="mb-4">
					Product ranking
				</h4>
				<CRow>
					<CCol>
						<CButton
							class="transparent mr-3"
							color="secondary"
							data-test-id="export-product-ranking"
							@click="exportProductRanking"
						>
							Export .xlsx
						</CButton>
						<CButton
							class="transparent"
							color="secondary"
							data-test-id="import-product-ranking"
							@click="importProductRanking"
						>
							Import .xlsx
						</CButton>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Product Ranking -->

			<!-- Filters -->
			<CCol lg="12">
				<h4 class="mb-4">
					Filters
				</h4>
				<CRow>
					<CCol xl="12" class="mb-3">
						{{ $t("page.categories.edit.filterDetail") }}
					</CCol>
					<CCol xl="12">
						<CButton
							:to="{
								name: 'CategoryAttributeEdit',
								params: {
									id: this.$route.params.id
								}
							}"
							color="secondary"
							class="transparent"
							data-id="manage-filter"
						>
							Manage filters
						</CButton>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end Filters -->

			<!-- Content -->
			<CCol lg="12">
				<h4 class="mb-4">
					Contents
				</h4>
				<CRow class="mt-4">
					<CCol lg="12">
						<label>Category banners</label>
						<CategoryBanners
							ref="categoryBanners"
							v-model="banners"
						/>
					</CCol>
				</CRow>
				<CRow class="mt-2">
					<CCol lg="12">
						<label>Category description (top)</label>
						<div class="d-flex mb-2">
							<label class="toggle-label -is-expand-content" for="category-auto-expand">
								Auto expand content
							</label>
							<CSwitch
								id="category-auto-expand"
								:checked="isExpand"
								variant="3d"
								size="sm"
								class="switch-custom toggle-visibility"
								color="success"
								@update:checked="handleUpdateExpand"
							/>
							<span>{{ expandText }}</span>
						</div>
						<BaseRichTextEditor
							v-model="descriptionTop"
							name="descriptionTop"
						/>
					</CCol>
				</CRow>
				<CRow class="mt-2">
					<CCol lg="12">
						<label>Category description (bottom)</label>
						<BaseRichTextEditor
							v-model="descriptionBottom"
							name="descriptionBottom"
						/>
					</CCol>
				</CRow>
				<hr class="mt-4 mb-5">
			</CCol>
			<!-- end Content -->
			<!-- SEO -->
			<CCol lg="12">
				<h4 class="mb-4">
					SEO
				</h4>
				<CRow>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaTitle"
							label="Meta title (EN)"
							rows="2"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaTitleTH"
							label="Meta title (TH)"
							rows="2"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaKeyword"
							label="Meta keywords (EN)"
							rows="3"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaKeywordTH"
							label="Meta keywords (TH)"
							rows="3"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaDescription"
							label="Meta description (EN)"
							rows="6"
							class="mb-0"
							@input="clearError"
						/>
					</CCol>
					<CCol md="6">
						<CTextarea
							v-model.trim="metaDescriptionTH"
							label="Meta description (TH)"
							rows="6"
							class="mb-0"
							@input="clearError"
						/>
					</CCol>
				</CRow>
				<hr>
			</CCol>
			<!-- end SEO -->
		</CRow>

		<BaseActionPanelStickyFooter
			:disabled-confirm="isUpdating"
			content-class="main-wrapper"
			is-edit
			remove-text="Remove category"
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'CategoryLists' })"
			@onRemove="handleOpenModalConfirmDelete"
		/>

		<!-- Remove modal confirm -->
		<CModal
			:show.sync="showModalConfirmDelete"
			:title="$t('page.categories.delete.modalConfirm.title')"
			centered
		>
			<template #default>
				<p>
					{{ $t("page.categories.delete.modalConfirm.description") }}
				</p>
				<form @submit.prevent="handleRemove">
					<CInput
						v-model.trim="inputConfirmText"
						:placeholder="$t('page.categories.delete.modalConfirm.inputPlaceholder')"
						class="mb-0"
					/>
				</form>
			</template>
			<template #footer>
				<CButton
					color="tertiary"
					class="mr-3"
					@click="showModalConfirmDelete = false"
				>
					Cancel
				</CButton>
				<CButton
					:disabled="!isDeletable || isDeleting"
					data-id="remove"
					color="danger"
					@click="handleRemove"
				>
					Remove
				</CButton>
			</template>
		</CModal>
	</form>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { required, helpers, url } from 'vuelidate/lib/validators';
import CategoryIcon from '@/components/CategoryIcon.vue';
import CategoryBanners from '@/components/CategoryBanners.vue';
import {
	CATEGORY_LEVEL,
	CATEGORY_LEVEL_OPTIONS,
	CONFIRM_DELETE_TEXT,
} from '../enums/categories';
import errorMessage from '../enums/errorMessage';
import { pathOr, scrollToTop } from '../assets/js/helpers';
import { transformedCategoryDropdownOption } from '../assets/js/transform/category';
import slug from '../regex/slug';
import { exportAPI } from '../services/api/export.api';
import { importProductRankingAPI } from '../services/api/categories.api';
import {
	STATUS as mappingStatus,
	VISIBILITY as mappingVisibility,
	EXPAND as mappingExpand,
} from '../mapping/categories';

const slugify = require('slugify');

export default {
	name: 'CategoryEditPage',
	components: {
		CategoryIcon,
		CategoryBanners,
	},
	validations() {
		// NOTE: Use code like this for use variable
		return {
			categoryParentLevelOne: {
				required: (value) => {
					if (this.isParentSelectable) {
						return required(value);
					}
					return true; // Pass when level 1
				},
			},
			categoryParentLevelTwo: {
				required: (value) => {
					if (this.isLevelThree) {
						return required(value);
					}
					return true; // Pass when level 1 & 2
				},
			},
			name: {
				required,
			},
			nameTH: {
				required,
			},
			slug: {
				required,
				format: helpers.regex('slug', slug),
				duplicate: () => !this.edit.fieldErrors.slug,
			},
			customUrl: {
				url,
			},
			link: {
				url,
			},
		};
	},
	data() {
		return {
			// Level
			CATEGORY_LEVEL_OPTIONS,
			level: null,
			categoryParentLevelOne: null,
			categoryParentLevelTwo: null,

			// General
			name: null,
			nameTH: null,
			slug: null,
			customUrl: null,
			commission: null,

			// Compare
			compare: null,
			compareTH: null,
			link: null,

			// Status
			isLoading: true,
			isNew: false,
			status: false,
			visibility: false,
			tradeInTruemoveVisibility: false,
			isExpand: false,

			// More info
			descriptionTop: '',
			descriptionBottom: '',
			bannerVisibility: false,

			// SEO
			metaTitle: null,
			metaTitleTH: null,
			metaKeyword: null,
			metaKeywordTH: null,
			metaDescription: null,
			metaDescriptionTH: null,

			// Media
			banners: [],
			icon: null,

			// Modal confirm delete
			showModalConfirmDelete: false,
			inputConfirmText: null,
		};
	},
	computed: {
		...mapState('categories', {
			edit: 'edit',
			delete: 'delete',
		}),
		...mapGetters({
			categoryLists: 'categories/categoryLists',
			categoryInfo: 'categories/categoryInfo',
		}),
		isUpdating() {
			return this.edit.isUpdating;
		},
		isDeleting() {
			return this.delete.isLoading;
		},

		// Categories dropdown options
		categoryParentLevelOneOptions() {
			return this.categoryLists.map(transformedCategoryDropdownOption);
		},
		categoryParentLevelTwoOptions() {
			if (this.categoryParentLevelOne) {
				const id = this.categoryParentLevelOne.value;
				const levelOneItem = this.categoryLists.find((category) => category.id === id) || {};
				const list = levelOneItem.children || [];

				return list.map(transformedCategoryDropdownOption);
			}
			return [];
		},

		isParentSelectable() {
			return this.level !== CATEGORY_LEVEL.ONE;
		},
		isLevelThree() {
			return this.level === CATEGORY_LEVEL.THREE;
		},
		isValidCategoryParentLevelOne() {
			const isError = pathOr(false, [
				'$v',
				'categoryParentLevelOne',
				'$error',
			])(this);
			return isError ? false : null;
		},
		validatecategoryParentLevelOneMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'categoryParentLevelOne',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidCategoryParentLevelTwo() {
			const isError = pathOr(false, [
				'$v',
				'categoryParentLevelTwo',
				'$error',
			])(this);
			return isError ? false : null;
		},
		validatecategoryParentLevelTwoMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'categoryParentLevelTwo',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		// For submit
		categoryParentId() {
			if (this.isParentSelectable && this.isLevelThree) {
				return pathOr(null, ['categoryParentLevelTwo', 'value'])(this);
			} else if (this.isParentSelectable) {
				return pathOr(null, ['categoryParentLevelOne', 'value'])(this);
			}
			return null;
		},
		isValidName() {
			const isError = pathOr(false, ['$v', 'name', '$error'])(this);
			return isError ? false : null;
		},
		validateNameMessage() {
			const validateRequired = pathOr(false, ['$v', 'name', 'required'])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidNameTH() {
			const isError = pathOr(false, ['$v', 'nameTH', '$error'])(this);
			return isError ? false : null;
		},
		validateNameTHMessage() {
			const validateRequired = pathOr(false, [
				'$v',
				'nameTH',
				'required',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isValidSlug() {
			const isError = pathOr(false, ['$v', 'slug', '$error'])(this);
			return isError ? false : null;
		},
		validateSlugMessage() {
			const validateRequired = pathOr(false, ['$v', 'slug', 'required'])(this);
			const validateFormat = pathOr(false, ['$v', 'slug', 'format'])(this);
			const validateDuplicate = pathOr(false, [
				'$v',
				'slug',
				'duplicate',
			])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			} else if (!validateFormat) {
				return this.$t('page.categories.create.slugFormat');
			} else if (!validateDuplicate) {
				return this.$t('page.categories.create.duplicateSlug');
			}
			return null;
		},
		slugHelperText() {
			return this.isValidSlug !== false
				? this.$t('page.categories.create.slugFormat')
				: '';
		},
		isNewText() {
			const text = mappingStatus[this.isNew] || '';
			return this.$t(text);
		},
		statusText() {
			const text = mappingStatus[this.status] || '';
			return this.$t(text);
		},
		visibilityText() {
			const text = mappingVisibility[this.visibility] || '';
			return this.$t(text);
		},
		visibilityTradeInText() {
			const text = mappingVisibility[this.tradeInTruemoveVisibility] || '';
			return this.$t(text);
		},
		isDeletable() {
			return this.inputConfirmText === CONFIRM_DELETE_TEXT;
		},
		expandText() {
			const text = mappingExpand[this.isExpand] || '';
			return this.$t(text);
		},
	},
	async created() {
		const categoryId = this.$route.params.id;

		await this.getCategories();
		await this.getCategory(categoryId);

		// Set category infomation
		this.level = this.categoryInfo.level;
		this.name = this.categoryInfo.name;
		this.nameTH = this.categoryInfo.nameTH;
		this.slug = this.categoryInfo.slug;
		this.customUrl = this.categoryInfo.customUrl;
		this.commission = this.categoryInfo.commission;
		this.status = this.categoryInfo.status;
		this.visibility = this.categoryInfo.visibility;
		this.tradeInTruemoveVisibility = this.categoryInfo.tradeInTruemoveVisibility;
		this.metaTitle = this.categoryInfo.seo.title;
		this.metaTitleTH = this.categoryInfo.seo.titleTH;
		this.metaKeyword = this.categoryInfo.seo.keyword;
		this.metaKeywordTH = this.categoryInfo.seo.keywordTH;
		this.metaDescription = this.categoryInfo.seo.description;
		this.metaDescriptionTH = this.categoryInfo.seo.descriptionTH;
		this.bannerVisibility = this.categoryInfo.bannerVisibility;
		this.descriptionTop = this.categoryInfo.descriptionTop;
		this.descriptionBottom = this.categoryInfo.descriptionBottom;
		this.isNew = this.categoryInfo.new;
		this.banners = this.categoryInfo.banners;
		this.icon = this.categoryInfo.icon;
		this.link = this.categoryInfo.link;
		this.compare = this.categoryInfo.compare;
		this.compareTH = this.categoryInfo.compareTH;
		this.categoryParentLevelOne =
			this.categoryParentLevelOneOptions.find((option) => option.value === this.categoryInfo.parentLevelOneId) || null;
		this.categoryParentLevelTwo =
			this.categoryParentLevelTwoOptions.find((option) => option.value === this.categoryInfo.parentLevelTwoId) || null;
		this.isExpand = this.categoryInfo.isExpand;

		// Set page title
		this.setPageTitle({
			title: this.name,
			isShow: true,
		});

		this.isLoading = false;
	},
	methods: {
		...mapActions({
			setPageTitle: 'pageTitle/setPageTitle',
			showToast: 'toast/showToast',
			getCategories: 'categories/getCategories',
			getCategory: 'categories/getCategory',
			updateCategory: 'categories/updateCategory',
			clearError: 'categories/clearEditError',
			deleteCategory: 'categories/deleteCategory',
		}),

		validateBanners() {
			const refs = this.$refs.categoryBanners.$refs.bannerInputMedia ?? [];
			refs.forEach((ref) => {
				ref.$v.$touch();
			});
		},
		checkValidBanners() {
			const refs = this.$refs.categoryBanners.$refs.bannerInputMedia ?? [];

			const isValid = refs
				.map((ref) => ref.$v.$invalid)
				.every((isInvalid) => isInvalid === false);

			return isValid;
		},
		async handleSubmit() {
			this.$v.$touch();
			this.validateBanners();

			if (!this.$v.$invalid && this.checkValidBanners()) {
				const categoryId = this.$route.params.id;
				const categoryData = {
					parent_id: this.categoryParentId,
					name_en: this.name,
					name_th: this.nameTH,
					slug: this.slug,
					custom_url: this.customUrl ? this.customUrl : null, // if is empty string change it to null
					is_new: this.isNew,
					is_active: this.status,
					is_visible: this.visibility,
					is_banner_visible: this.bannerVisibility,
					is_trade_in_truemove_visible: this.tradeInTruemoveVisibility,
					media: {
						banners: [],
						icons: this.icon ? [this.icon.id] : [],
					},
					banners: this.banners?.map((banner) => {
						return {
							file_id: banner?.file.id,
							link: banner.link,
						};
					}),
					description_top: this.descriptionTop,
					description_bottom: this.descriptionBottom,
					seo: {
						title_en: this.metaTitle,
						title_th: this.metaTitleTH,
						description_en: this.metaDescription,
						description_th: this.metaDescriptionTH,
						keyword_en: this.metaKeyword,
						keyword_th: this.metaKeywordTH,
					},
					commission: this.commission,
					banner_link: null,
					compare_link: this.link,
					compare_text_en: this.compare,
					compare_text_th: this.compareTH,
					is_description_top_expanded: this.isExpand,
				};

				await this.updateCategory({ categoryId, categoryData });

				// Show toast success
				if (this.edit.isSuccess) {
					this.showToast({
						content: this.$t('global.successMessage'),
						header: this.$t('global.successMessageTitle'),
						type: 'success',
					});

					// Set page title
					this.setPageTitle({
						title: this.name,
						isShow: true,
					});

					// Show toast if api return any errors
				} else {
					let content = this.$t('global.errorMessage');
					if (this.edit.fieldErrors.slug) {
						content = this.$t('page.categories.create.duplicateSlug');
					}

					this.showToast({
						content,
						header: this.$t('global.errorMessageTitleEdit'),
						type: 'danger',
					});
				}

				scrollToTop();
			}
		},
		async handleRemove() {
			if (this.isDeletable && !this.isDeleting) {
				await this.deleteCategory(this.$route.params.id);

				this.showModalConfirmDelete = false;

				// when success re-direct to category list page
				if (this.delete.isSuccess) {
					this.$router.push({ name: 'CategoryLists' });

					// Show toast if api return any errors
				} else {
					this.showToast({
						content: this.$t('global.errorMessage'),
						header: this.$t('global.errorMessageTitleDelete'),
						type: 'danger',
					});
				}
			}
		},

		handleSelectedCategoryParentLevelOne() {
			this.clearError();

			// Should clear parent id level 2 value
			this.categoryParentLevelTwo = null;
		},
		handleUpdateStatus(value) {
			this.status = value;
			this.clearError();

			// When set status to false, should set visibility to false too
			if (value === false) {
				this.handleUpdateVisibility(false);
				this.handleUpdateTradeInTruemoveVisibility(false);
			}
		},
		handleUpdateVisibility(value) {
			this.visibility = value;
			this.clearError();
		},
		handleUpdateTradeInTruemoveVisibility(value) {
			this.tradeInTruemoveVisibility = value;
			this.clearError();
		},
		handleUpdateIsNew(value) {
			this.isNew = value;
			this.clearError();
		},
		handleAutoFillMetaTitleAndSlug() {
			if (!this.metaTitle) {
				this.metaTitle = this.name;
			}

			if (!this.slug) {
				this.slug = slugify(this.name, {
					lower: true,
					strict: true,
				});
				this.$v.slug.$touch();
			}
		},
		handleAutoFillMetaTitleTH() {
			if (!this.metaTitleTH) {
				this.metaTitleTH = this.nameTH;
			}
		},
		handleOpenModalConfirmDelete() {
			this.inputConfirmText = null;
			this.showModalConfirmDelete = true;
		},
		exportProductRanking() {
			const categoryId = this.$route.params.id;
			exportAPI({
				key: 'product_ranking',
				category_id: categoryId,
			});
		},
		importProductRanking() {
			const categoryId = this.$route.params.id;
			const fileSelector = document.createElement('input');
			fileSelector.setAttribute('type', 'file');
			fileSelector.setAttribute(
				'accept',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
			);
			fileSelector.onchange = async () => {
				const file = fileSelector.files.item(0);
				const params = {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};

				try {
					await importProductRankingAPI(file, categoryId, params);
					this.showToast({
						content: 'Product ranking successfully uploaded. It could take up to 30 minutes for the result to take effect.',
						header: 'Success',
						type: 'success',
					});
				} catch (e) {
					this.showToast({
						content: 'Product ranking upload failed',
						header: 'Failure',
						type: 'danger',
					});
				}
			};
			fileSelector.click();
		},
		handleUpdateExpand(value) {
			this.isExpand = value;
			this.clearError();
		},
	},
};
</script>

<style lang="scss" scoped>
hr {
	margin: rem(40) 0;
}

.level-label {
	margin-bottom: rem(4);
}

.toggle-label {
	width: rem(200);
	margin: 0 rem(24) 0 0;

	&.-is-expand-content {
		width: auto !important;
	}
}

.toggle-status,
.toggle-visibility {
	margin-right: rem(12);
}

.row-parent-category {
	// margin left, right come from .row
	margin-top: rem(40);
	margin-bottom: rem(-40);
}

.margin-bottom-0 {
	// .margin-bottom-0
	&-important {
		margin-bottom: rem(0) !important;
	}
}

.margin-bottom-32 {
	margin-bottom: rem(32);
}

.form-group {
	margin-bottom: rem(40);
}

.category-id {
	color: $color-black-70;
}
</style>
